import { useEffect } from "react";
import "./App.css";
import { useTranslation } from "react-i18next";
import EmailConfirmation from "./pages/EmailConfirmation";
import ResetPassword from "./pages/ResetPassword";

const getParameterByName = (param) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
};

function App() {
  const { i18n } = useTranslation();

  const mode = getParameterByName("mode");
  const actionCode = getParameterByName("oobCode");
  const lang = getParameterByName("lang") || "en";
  const continueUrl = getParameterByName("continueUrl");
  useEffect(() => {
    if (lang !== "en") {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);
  switch (mode) {
    case "resetPassword":
      return (
        <ResetPassword
          oobCode={actionCode}
          continueUrl={continueUrl}
          locale={lang}
        />
      );
    case "verifyEmail":
      return (
        <EmailConfirmation
          oobCode={actionCode}
          continueUrl={continueUrl}
          locale={lang}
        />
      );
    default:
      return <h1>404 mode not found</h1>;
  }
}

export default App;
