import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      title: "Create a new Password",
      "input.password.placeholder": "New Password",
      "input.confirmPassword.placeholder": "Confirm Password",
      "verification.password": "Your password must contain: ",
      "verification.length": "6 or more characters",
      "verification.uppercase": "Uppercase",
      "verification.lowercase": "Lowercase",
      "verification.number": "Number",
      "button.save": "Save",
      "password.confirmation.title":
        "Your new password was created with success",
      "password.confirmation.text":
        "We are redirecting you for the app.\n Don't forget to realize the login with your new password",
      "email.confirmation.title": "Email confirmed with success",
      "email.confirmation.text":
        "We will redirect you to the app for you finish your registration",
      "password.error":
        "The password must match with the required fields below",
      "password.required.error": "Required Field",
      "password.length.error": "Password must contain at least 6 characters",
      "password.uppercase.error": "Password must contain a uppercase letter",
      "password.lowercase.error": "Password must contain a lowercase letter",
      "password.number.error": "Password must contain a number",
      "password.match.error": "Passwords must match",
    },
  },
  "pt-BR": {
    translation: {
      title: "Crie uma nova senha",
      "input.password.placeholder": "Nova senha",
      "input.confirmPassword.placeholder": "Repita a senha",
      "verification.password": "A senha deve conter: ",
      "verification.length": "6 ou mais caracteres",
      "verification.uppercase": "Maiúscula",
      "verification.lowercase": "Minúscula",
      "verification.number": "Número",
      "button.save": "Salvar",
      "password.confirmation.title": "Sua nova senha foi criada com sucesso",
      "password.confirmation.text1": "Estamos te direcionando para o App.",
      "password.confirmation.text2":
        "Não se esqueça de realizar o login com sua nova senha",
      "email.confirmation.title": "Email confirmado com sucesso",
      "email.confirmation.text":
        "Vamos te redirecionar para o aplicativo para que possa terminar o seu registro",
      "password.error":
        "Digite uma senha que atenda aos requisitos de segurança listados abaixo",
      "password.required.error": "Complete os campos obrigatórios",
      "password.length.error": "A senha deve contar 6 ou mais caracteres",
      "password.uppercase.error": "A senha deve conter uma letra maiúscula",
      "password.lowercase.error": "A senha deve conter uma letra minúscula",
      "password.number.error": "A senha deve conter um número",
      "password.match.error": "As senhas digitadas não são iguais",
    },
  },
  es: {
    translation: {
      title: "Crea tu nueva contraseña",
      "input.password.placeholder": "Nueva Contraseña",
      "input.confirmPassword.placeholder": "Repetir Nueva Contraseña",
      "verification.password": "A senha deve conter: ",
      "verification.length": "6 caracteres o más",
      "verification.uppercase": "Mayúscula",
      "verification.lowercase": "Minúscula",
      "verification.number": "Número",
      "button.save": "Guardar",
      "password.confirmation.title": "Tu nueva contraseña ha sido creada.",
      "password.confirmation.text":
        "Estamos redirigiendote a la App. \n Recuerda ingresar con tu nueva contraseña.",
      "email.confirmation.title": "Email validado con éxito",
      "email.confirmation.text":
        "Te redigiremos a la App para que puedas terminar tu registro.",
      "password.error":
        "Ingrese una contraseña que cumpla con los requisitos de seguridad mencionados debajo.",
      "password.required.error": "Completa los campos obligatorios",
      "password.length.error":
        "La contraseña debe contener al menos 6 caracteres",
      "password.uppercase.error": "La contraseña debe contener una mayúscula",
      "password.lowercase.error": "La contraseña debe contener una minúscula",
      "password.number.error": "La contraseña debe contener un numero",
      "password.match.error":
        "Parece que las contraseñas que ingresaste no coinciden.",
    },
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
