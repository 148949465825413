import "../style/resetPassword.css";
import { useCallback, useEffect, useState } from "react";
import { app } from "../firebase/firebase.js";
import { useTranslation } from "react-i18next";
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
import {
  checkPasswordContainsLowercase,
  checkPasswordContainsUppercase,
  checkPasswordHasNumber,
  checkPasswordLength,
  passwordAndConfirmationEqual,
} from "../helpers/passwordValidation.js";
import CheckFalse from "../assets/check-false.js";
import CheckTrue from "../assets/check-true.js";
import EyeOpen from "../assets/eye-open.js";
import EyeClosed from "../assets/eye-closed.js";
import LockIcon from "../assets/lock-icon.js";

const handleResetPassword = async (auth, oobCode, password) => {
  await confirmPasswordReset(auth, oobCode, password);
};

const handlePasswordValidation = (
  password,
  confirmation,
  setPasswordCondition
) => {
  const length = checkPasswordLength(password);
  const containsUppercase = checkPasswordContainsUppercase(password);
  const containsLowercase = checkPasswordContainsLowercase(password);
  const hasNumber = checkPasswordHasNumber(password);
  const confirmed = passwordAndConfirmationEqual(password, confirmation);

  setPasswordCondition({
    length,
    containsUppercase,
    containsLowercase,
    hasNumber,
    confirmed,
  });
};

const handlePasswordError = (
  t,
  password,
  setPasswordError,
  setPasswordHasError
) => {
  const length = checkPasswordLength(password);
  const containsUppercase = checkPasswordContainsUppercase(password);
  const containsLowercase = checkPasswordContainsLowercase(password);
  const hasNumber = checkPasswordHasNumber(password);
  if (password === "") {
    setPasswordError(t("password.required.error"));
    setPasswordHasError(true);
    return true;
  }
  if (length === 0) {
    setPasswordError(t("password.error"));
    setPasswordHasError(true);
    return true;
  }
  if (!containsUppercase) {
    setPasswordError(t("password.error"));
    setPasswordHasError(true);
    return true;
  }
  if (!containsLowercase) {
    setPasswordError(t("password.error"));
    setPasswordHasError(true);
    return true;
  }
  if (!hasNumber) {
    setPasswordError(t("password.error"));
    setPasswordHasError(true);
    return true;
  }
};

const handlePasswordConfirmationError = (
  t,
  password,
  passwordConfirmation,
  setPasswordConfirmationError,
  setPasswordHasError
) => {
  const confirmed = passwordAndConfirmationEqual(
    password,
    passwordConfirmation
  );
  if (passwordConfirmation === "") {
    setPasswordConfirmationError(t("password.required.error"));
    setPasswordHasError(true);
    return true;
  }
  if (!confirmed) {
    setPasswordConfirmationError(t("password.match.error"));
    setPasswordHasError(true);
    return true;
  }
};

const redirect = (url) => {
  if (url) {
    window.location.replace(url);
  }
  return;
};

function ResetPassword({ oobCode, continueUrl }) {
  const { t } = useTranslation();
  const auth = getAuth(app);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [password, setPassword] = useState("");
  const [passwordCondition, setPasswordCondition] = useState({
    length: false,
    containsUppercase: false,
    containsLowercase: false,
    hasNumber: false,
    confirmed: false,
  });
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordHasError, setPasswordHasError] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] =
    useState("");
  const [email, setEmail] = useState("");
  const [passwordSent, setPasswordSent] = useState(false);
  const [passwordVerified, setPasswordVerified] = useState(false);

  const verifyPassword = useCallback(() => {
    verifyPasswordResetCode(auth, oobCode)
      .then((email) => {
        setEmail(email);
        setPasswordVerified(true);
      })
      .catch((err) => console.log(err));
  }, [auth, oobCode]);

  useEffect(() => {
    if (!passwordVerified) {
      verifyPassword();
    }
  });

  let inputPasswordClass = "input-true";
  let inputConfirmPasswordClass = "input-true";
  let passwordIcon = <EyeOpen />;
  let confirmPasswordIcon = <EyeOpen />;

  passwordError !== ""
    ? (inputPasswordClass = "input-false")
    : (inputPasswordClass = "input-true");

  passwordConfirmationError !== ""
    ? (inputConfirmPasswordClass = "input-false")
    : (inputConfirmPasswordClass = "input-true");

  showPassword ? (passwordIcon = <EyeClosed />) : (passwordIcon = <EyeOpen />);
  showPasswordConfirmation
    ? (confirmPasswordIcon = <EyeClosed />)
    : (confirmPasswordIcon = <EyeOpen />);

  return (
    <div className="container-reset">
      {passwordSent ? (
        <div className="confirmation-container">
          <span>
            <LockIcon />
          </span>
          <h3 className="green-title">{t("password.confirmation.title")}</h3>
          <p className="green-text">
            {t("password.confirmation.text1")}
            <br /> {t("password.confirmation.text2")}
          </p>
          {continueUrl !== "" ? redirect(continueUrl) : ""}
        </div>
      ) : (
        <>
          <div>
            <h3 className="main-title"> {t("title")}</h3>
            <hr />
          </div>
          <div>
            <p>
              Email
              <span style={{ marginLeft: "2px" }}>
                <b> {email}</b>
              </span>
            </p>
          </div>

          <div className="input-container">
            <input
              className={inputPasswordClass}
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder={t("input.password.placeholder")}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordHasError(false);
                setPasswordError("");
                handlePasswordValidation(
                  e.target.value,
                  passwordConfirmation,
                  setPasswordCondition
                );
              }}
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              id="showPasswordIcon"
            >
              {passwordIcon}
            </span>
            {passwordHasError ? <p className="error">{passwordError}</p> : ""}
            <input
              name="confirm-password"
              type={showPasswordConfirmation ? "text" : "password"}
              className={inputConfirmPasswordClass}
              placeholder={t("input.confirmPassword.placeholder")}
              onChange={(e) => {
                setPasswordConfirmation(e.target.value);
                setPasswordHasError(false);
                setPasswordConfirmationError("");
                handlePasswordValidation(
                  password,
                  e.target.value,
                  setPasswordCondition
                );
              }}
            />
            <span
              onClick={() =>
                setShowPasswordConfirmation(!showPasswordConfirmation)
              }
              id="showPasswordConfirmationIcon"
            >
              {confirmPasswordIcon}
            </span>
            {passwordHasError ? (
              <p className="error">{passwordConfirmationError}</p>
            ) : (
              ""
            )}
            <div className="password-conditions">
              <p>{t("verification.password")} </p>
              <ul>
                <li>
                  {" "}
                  <p>
                    <span className="icon-container">
                      {passwordCondition.length ? (
                        <CheckTrue />
                      ) : (
                        <CheckFalse />
                      )}
                    </span>
                    {t("verification.length")}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="icon-container">
                      {passwordCondition.containsUppercase ? (
                        <CheckTrue />
                      ) : (
                        <CheckFalse />
                      )}
                    </span>
                    {t("verification.uppercase")}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="icon-container">
                      {passwordCondition.containsLowercase ? (
                        <CheckTrue />
                      ) : (
                        <CheckFalse />
                      )}
                    </span>
                    {t("verification.lowercase")}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="icon-container">
                      {passwordCondition.hasNumber ? (
                        <CheckTrue />
                      ) : (
                        <CheckFalse />
                      )}
                    </span>
                    {t("verification.number")}
                  </p>
                </li>
              </ul>
            </div>
            <div className="button-container">
              <button
                onClick={(e) => {
                  const passwordError = handlePasswordError(
                    t,
                    password,
                    setPasswordError,
                    setPasswordHasError
                  );
                  const passwordConfirmationError =
                    handlePasswordConfirmationError(
                      t,
                      password,
                      passwordConfirmation,
                      setPasswordConfirmationError,
                      setPasswordHasError
                    );
                  console.log("passwordHasError A: ", passwordError);
                  console.log(
                    "passwordHasError B: ",
                    passwordConfirmationError
                  );
                  if (passwordError || passwordConfirmationError) {
                    setPasswordSent(false);
                    return;
                  } else {
                    handleResetPassword(auth, oobCode, password.trim());
                    setPasswordSent(true);
                    return;
                  }
                }}
              >
                {t("button.save")}
              </button>
              <p></p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ResetPassword;
